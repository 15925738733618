<template>
  <div id="signup">         
    
  <section class="container mt-5">

    <div class="row justify-content-center">
    
    <Form @submit="onSubmit" :validation-schema="schema">
      
      <TextInput
        name="email"
        type="email"
        label="E-mail"
      />

      <button type="submit" class="btn btn-custom">Reset Password</button>

    </Form>

      <div class="login-social row d-block justify-content-center mt-5">
        
        <ul class="d-flex justify-content-center">
          <li class="list-group-item">
            <!-- google	 -->
            <button v-on:click="loginWithGoogle" type="button" class="login-with-google-btn" >
              Sign in with Google
            </button>
          </li>
          <!-- <li class="list-group-item">
            <button v-on:click="loginWithFacebook" type="button" class="login-with-facebook-btn" >
              Sign in with Facebook
            </button>

          </li> -->
        </ul>

      </div>

    </div>
  </section>

  <Footer/>
  </div>
</template>

<script>

import Api from '../scripts/api'

import Footer from '../components/global/Footer'

import { Form } from "vee-validate";
import * as Yup from "yup";
import TextInput from "../components/Form/TextInput";

export default {
    components: {
        Footer,
        Form,
        TextInput
    },
    setup() {
        var schema = Yup.object().shape({
          email: Yup.string().min(6).required(),
        });
        return {
          schema,
        };
    },
    methods: {
        onSubmit: function(values) {
            this.resetPassword(values.email);
        },
        resetPassword: async function(email) {
            var result = await Api.resetPassword(email);
            if(result.status == 200) return this.$toast.success('If e-mail is correct, you will receive an email shortly.');
            if(result.status == 404) return this.$toast.error('If e-mail is correct, you will receive an email shortly.');
            return this.$toast.error('An error occured, try again later.');
        },
        loginWithGoogle: async function() {
            var redirectionurl = 'http://127.0.0.1:80/api/account/login/google';
            var clientid = '262208347834-2c5bufdre43uuqs4hppea8c7k7r885g0.apps.googleusercontent.com'
            var url = "https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20email&include_granted_scopes=true&redirect_uri=" + redirectionurl + "&response_type=code&client_id=" + clientid + "";  
            window.location.href = url;  
        },
        loginWithFacebook: async function() {
            window.location.href = "https://www.facebook.com/v10.0/dialog/oauth?client_id=246848313606586&redirect_uri=https%3A%2F%2Fcompetitionmania.co.uk%2Fapi%2Faccount%2Flogin%2Ffacebook&state=1";
        }
    }
}
</script>

<style>

:root {
  --primary-color: #0071fe;
  --error-color: #f23648;
  --error-bg-color: #fddfe2;
  --success-color: #21a67a;
  --success-bg-color: #e0eee4;
}
.submit-btn {
  background: var(--primary-color);
  outline: none;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  display: block;
  width: 100%;
  border-radius: 7px;
  margin-top: 40px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.submit-btn:hover {
  transform: scale(1.1);
}
</style>
+-